<template>
  <div>
    <b-modal
      centered
      hide-footer
      id="edit-balance"
      @show="editBalance = ''"
      @hide="editBalance = ''"
    >
      <div>
        <b-form-input v-model="editBalance"> </b-form-input>
        <br />
      </div>
      <div>
        <b-button-group>
          <b-button
            variant="outline-secondary"
            @click="app.balance = Number(app.balance) + Number(editBalance)"
          >
            Начислить
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="app.balance = Number(app.balance) - Number(editBalance)"
          >
            Списать
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="app.balance = app.balance - app.fare"
            >Списать по тарифу
          </b-button>
        </b-button-group>
      </div>
    </b-modal>

    <b-modal centered hide-footer size="lg" v-model="modal" :title="app.title">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Приложение" active>
            <label for="input-live">Название:</label>
            <b-form-input
              id="input-live"
              v-model="app.name"
              :disabled="!!app.name"
              placeholder="Введите название"
              class="mb-3"
              trim
            ></b-form-input>
            <label for="input-live">Токен:</label>
            <b-form-input
              id="input-live"
              v-model="app.token"
              disabled
              placeholder="Введите название"
              class="mb-3"
              trim
            ></b-form-input>
            <label for="input-live">Заголовок:</label>
            <b-form-input
              id="input-title"
              v-model="app.title"
              placeholder="Введите название"
              class="mb-3"
              trim
            ></b-form-input>

            <label for="input-live">Описание:</label>
            <b-form-textarea
              id="input-title"
              v-model="app.description"
              class="mb-3"
              trim
            ></b-form-textarea>

            <label for="input-live">Тариф:</label>
            <b-form-input
              id="input-title"
              v-model="app.fare"
              class="mb-3"
              trim
            ></b-form-input>

            <label for="input-live">Баланс:</label>
            <b-form-input
              disabled
              id="input-title"
              v-model="app.balance"
              class="mb-3"
              trim
            ></b-form-input>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 5px;
              "
            >
              <b-button
                variant="outline-secondary"
                @click="$bvModal.show('edit-balance')"
                >Изменить баланс
              </b-button>
            </div>

            <label for="pay-datepicker">Дата последнего платежа:</label>
            <b-form-datepicker
              id="pay-datepicker"
              v-model="app.dateOfLastPay"
              class="mb-2"
            ></b-form-datepicker>

            <label for="sub-datepicker">Дата окончания подписки:</label>
            <b-form-datepicker
              id="sub-datepicker"
              v-model="app.dateOfSubEnd"
              class="mb-2"
            ></b-form-datepicker>

            <label for="input-live">Магазины:</label>
            <b-form-input
              id="input-title"
              v-model="app.connectedStores"
              class="mb-3"
              type="number"
              trim
            ></b-form-input>
            <div class="d-flex">
              <b-button
                @click="saveApp"
                class="ml-auto"
                style="margin-left: auto"
                variant="success"
              >
                Сохранить
              </b-button>
            </div>
          </b-tab>
          <b-tab title="Пресет">
            <label for="input-live">Название:</label>
            <b-form-input
              id="input-title"
              v-model="preset.app_title"
              placeholder="Введите название"
              class="mb-3"
              trim
            ></b-form-input>
            <label for="input-live">Токен:</label>
            <b-form-input
              id="input-title"
              v-model="preset.app_token"
              placeholder="Введите токен"
              class="mb-3"
              trim
            ></b-form-input>
            <div class="d-flex">
              <div style="flex: 1" class="me-3">
                <label for="input-live">Цвет:</label>
                <b-form-input
                  id="input-title"
                  v-model="preset.main_color"
                  class="mb-3"
                  type="color"
                  trim
                ></b-form-input>
              </div>
              <div style="flex: 1">
                <label for="input-live">Дополнительный цвет:</label>
                <b-form-input
                  id="input-title"
                  v-model="preset.second_color"
                  class="mb-3"
                  type="color"
                  trim
                ></b-form-input>
              </div>
            </div>

            <b-form-group label="Тип магазина" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                class="d-flex"
                style="margin-right: 20px"
                id="radio-group-1"
                v-model="preset.shop_type"
                :options="shop_type_options"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
            <div v-if="preset.shop_type === 'web'">
              <label for="input-live">Ссылка на магазин:</label>
              <b-form-input
                id="input-title"
                v-model="preset.web_shop_url"
                placeholder="Введите Ссылку"
                class="mb-3"
                trim
              ></b-form-input>
            </div>
            <div class="d-flex mt-3 mb-3" style="overflow: auto">
              <b-form-checkbox
                id="checkbox-1"
                class="me-3"
                v-model="preset.cart"
                name="Корзина"
                value="true"
                unchecked-value="false"
              >
                Корзина
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-2"
                class="me-3"
                v-model="preset.popular"
                name="Популярные"
                value="true"
                unchecked-value="false"
              >
                Популярные
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-3"
                class="me-3"
                v-model="preset.clm"
                name="Баллы"
                value="true"
                unchecked-value="false"
              >
                Баллы
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-4"
                class="me-3"
                v-model="preset.shop_delivery"
                name="Доставка"
                value="true"
                unchecked-value="false"
              >
                Доставка
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-13"
                class="me-3"
                v-model="preset.pickup"
                name="Самовывоз"
                value="true"
                unchecked-value="false"
              >
                Самовывоз
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-5"
                class="me-3"
                v-model="preset.shop_online_pay"
                name="Онлайн оплата"
                value="true"
                unchecked-value="false"
              >
                Онлайн оплата
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-6"
                class="me-3"
                v-model="preset.scan_card"
                name="Скан карты"
                value="true"
                unchecked-value="false"
              >
                Скан карты
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-7"
                class="me-3"
                v-model="preset.not_show_discount"
                name="Скан карты"
                value="true"
                unchecked-value="false"
              >
                не показывать
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-8"
                class="me-3"
                v-model="preset.qr_screen"
                name="Скидка"
                value="true"
                unchecked-value="false"
              >
                не показывать скидку
              </b-form-checkbox>

              <b-form-checkbox
                  id="checkbox-9"
                  class="me-3"
                  v-model="preset.promocode"
                  name="Промокод"
                  value="true"
                  unchecked-value="false"
              >
                Промокод
              </b-form-checkbox>

              <b-form-checkbox
                  id="checkbox-10"
                  class="me-3"
                  v-model="preset.discount_matrix"
                  name="Матрица скидок"
                  value="true"
                  unchecked-value="false"
              >
                Матрица скидок
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-11"
                class="me-3"
                v-model="preset.shop_cash_in_delivery"
                name="Скан карты"
                value="true"
                unchecked-value="false"
              >
                оплата наличкой при доставке
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-12"
                class="me-3"
                v-model="preset.product_scan"
                name="Скан карты"
                value="true"
                unchecked-value="false"
              >
                Скан продуктов
              </b-form-checkbox>
            </div>

            <div>
              <label for="input-live">URL поделиться:</label>
              <b-form-input
                id="input-live"
                v-model="preset.share_url"
                placeholder="Введите ссылку"
                class="mb-3"
                trim
              ></b-form-input>

              <label for="input-live">Заголовок поделиться:</label>
              <b-form-input
                id="input-live"
                v-model="preset.share_title"
                placeholder="Введите название"
                class="mb-3"
                trim
              ></b-form-input>

              <label for="input-live">Текст поделиться:</label>
              <b-form-textarea
                id="input-live"
                v-model="preset.share_message"
                placeholder="Введите название"
                class="mb-3"
                trim
              ></b-form-textarea>
            </div>
            <div class="d-flex">
              <div class="me-4">
                <label for="input-live">Широта:</label>
                <b-form-input
                  id="input-live"
                  v-model="preset.init_lat"
                  placeholder="Широта"
                  class="mb-3"
                  trim
                ></b-form-input>
              </div>
              <div>
                <label for="input-live">Долгота:</label>
                <b-form-input
                  id="input-live"
                  v-model="preset.init_lon"
                  placeholder="Долгота"
                  class="mb-3"
                  trim
                ></b-form-input>
              </div>
            </div>
            <div class="mb-3">
              Андроид
              <b-form-checkbox
                class="me-3"
                v-model="preset.android.permissions"
                name="Камера"
                value="CAMERA"
              >
                Камера
              </b-form-checkbox>
            </div>
            <div class="mb-5">
              <div class="mb-2">Иос</div>
              <div v-for="(el, index) in preset.ios.infoPlist" :key="index">
                {{ index }}
                <div class="d-flex mb-3">
                  <b-form-input
                    v-model="preset.ios.infoPlist[index]"
                    class="me-3"
                    :plceholder="index"
                    trim
                    >{{ index }}
                  </b-form-input>
                  <b-button variant="danger" @click="dellIos(index)"
                    >Удалить</b-button
                  >
                </div>
              </div>

              <div class="d-flex mb-3">
                <select
                  v-model="iosSelect"
                  class="form-select me-3"
                  aria-label="Default select example"
                >
                  <option v-for="(el, index) in iosOptions" :value="el.value">
                    {{ el.text }}
                  </option>
                </select>
                <b-button @click="addIos">Добавить</b-button>
              </div>
            </div>
            <div class="d-flex">
              <b-button
                class="ml-auto"
                style="margin-left: auto"
                variant="success"
                @click="savePreset"
              >
                Сохранить
              </b-button>
            </div>
          </b-tab>
          <b-tab title="Лого">
            <div class="mb-3">
              <div>
                <h4>Лого в меню</h4>
              </div>
              <div
                style="height: 100px; background-color: #e5e5e5"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-2
                  p-3
                "
              >
                <img style="height: 90px" :src="menu_logo_url" />
              </div>

              <div class="d-flex">
                <input
                  ref="menu_logo"
                  @change="setMenuLogo"
                  accept=".svg"
                  class="form-control"
                  type="file"
                  id="formFile"
                />
                <b-button
                  @click="sendMenuLogo"
                  :disabled="!menu_logo_file"
                  class="ms-3"
                  >Загрузить
                </b-button>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <h4>Иконка</h4>
              </div>
              <div
                style="height: 100px; background-color: #e5e5e5"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-2
                  p-3
                "
              >
                <img style="height: 90px" :src="icon_url" />
              </div>

              <div class="d-flex">
                <input
                  ref="icon"
                  @change="setIcon"
                  accept=".png"
                  class="form-control"
                  type="file"
                />
                <b-button @click="sendIcon" :disabled="!icon_file" class="ms-3"
                  >Загрузить
                </b-button>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <h4>splash</h4>
              </div>
              <div
                style="height: 200px; background-color: #e5e5e5"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-2
                  p-3
                "
              >
                <img style="height: 100%" :src="splash_url" />
              </div>

              <div class="d-flex">
                <input
                  ref="splash"
                  @change="setSplash"
                  accept=".png"
                  class="form-control"
                  type="file"
                />
                <b-button
                  @click="sendSplash"
                  :disabled="!splash_file"
                  class="ms-3"
                  >Загрузить
                </b-button>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <h4>google service</h4>
              </div>

              <div class="d-flex">
                <input
                  ref="google_service"
                  @change="setGoogleService"
                  accept=".json"
                  class="form-control"
                  type="file"
                />
                <b-button
                  @click="sendGoogleService"
                  :disabled="!google_service_file"
                  class="ms-3"
                >
                  Загрузить
                </b-button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
    <b-container class="mt-5">
      <b-card>
        <div class="d-flex">
          <b-button class="ms-auto" @click="openModal">Добавить</b-button>
        </div>
        <b-table
          fixed
          hover
          :fields="table.fields"
          :items="apps"
          :busy="isBusy"
          @row-clicked="openModal"
        >
          <template v-slot:cell(remain)="{ item }">
            {{ calculateDays(item.dateOfSubEnd) }}
          </template>

          <template v-slot:cell(status)="{ item }">
            <li
              v-for="integration of item.status"
              :key="integration"
              v-bind:class="{
                'text-success': integration.status,
                'text-danger': !integration.status,
              }"
            >
              {{ integration.service_name + ": " }}
              {{ integration.status === true ? "Доступен" : "Не доступен" }}
            </li>
          </template>

          <template v-slot:cell(configs)="{ item }">
            <li
              v-bind:class="{
                'text-success': item.configs && item.configs.minAge,
                'text-danger': !(item.configs && item.configs.minAge),
              }"
            >
              Минимальный возраст:
              {{ item.configs && item.configs.minAge ? "✓" : "✖" }}
            </li>
            <li
              v-bind:class="{
                'text-success': item.configs && item.configs.loyalty,
                'text-danger': !(item.configs && item.configs.loyalty),
              }"
            >
              Программа лояльности:
              {{ item.configs && item.configs.loyalty ? "✓" : "✖" }}
            </li>
            <!--            <li-->
            <!--                v-bind:class="{'text-success':item.configs&&item.configs.policy,'text-danger':!(item.configs&&item.configs.policy)}"-->
            <!--            >Конфиденциальность: {{item.configs&&item.configs.policy?'✓':'✖'}}</li>-->
            <li
              v-bind:class="{
                'text-success': item.configs && item.configs.TermsOfUse,
                'text-danger': !(item.configs && item.configs.TermsOfUse),
              }"
            >
              Усл. использования:
              {{ item.configs && item.configs.TermsOfUse ? "✓" : "✖" }}
            </li>
            <li
              v-if="item.presets && item.presets.cart"
              v-bind:class="{
                'text-success': item.configs && item.configs.contact,
                'text-danger': !(item.configs && item.configs.contact),
              }"
            >
              Контакты: {{ item.configs && item.configs.contact ? "✓" : "✖" }}
            </li>
            <li
              v-if="item.presets && item.presets.cart"
              v-bind:class="{
                'text-success': item.configs && item.configs.delivery,
                'text-danger': !(item.configs && item.configs.delivery),
              }"
            >
              Усл. доставки:
              {{ item.configs && item.configs.delivery ? "✓" : "✖" }}
            </li>
            <li
              v-if="item.presets && item.presets.cart"
              v-bind:class="{
                'text-success': item.configs && item.configs.banks,
                'text-danger': !(item.configs && item.configs.banks),
              }"
            >
              Реквизиты: {{ item.configs && item.configs.banks ? "✓" : "✖" }}
            </li>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      iosSelect: "",
      iosOptions: [
        { value: "NSCameraUsageDescription", text: "NSCameraUsageDescription" },
      ],
      apps: [],
      table: {
        fields: [
          { key: "name", label: "название" },
          { key: "title", label: "Заголовок" },
          { key: "description", label: "Описание" },
          { key: "remain", label: "Оплачено дней" },
          { key: "connectedStores", label: "Магазины" },
          { key: "status", label: "Состояние интеграций" },
          { key: "configs", label: "Конфиг", class: "min-width" },
        ],
      },
      app: {
        name: "",
        title: "",
        description: "",
        connectedStores: 0,
        balance: 0,
        fare: 0,
        dateOfLastPay: Date.now(),
        dateOfSubEnd: Date.now(),
        service_status: true,
      },
      shop_type_options: [
        { value: "native", text: "Встроенный" },
        { value: "web", text: "Веб" },
        { value: "none", text: "Без каталога" },
      ],
      preset: {
        app_title: "",
        app_token: "",
        main_color: "",
        second_color: "",
        shop_type: "",
        cart: false,
        popular: false,
        promocode: false,
        discount_matrix: false,
        clm: false,
        shop_delivery: false,
        shop_online_pay: false,
        scan_card: false,
        not_show_discount: false,
        shop_cash_in_delivery: false,
        product_scan: false,
        qr_screen: false,
        web_shop_url: "",
        share_title: "",
        share_message: "",
        pickup: false,
        share_url: "",
        init_lat: "",
        init_lon: "",
        menu_logo: "", //
        icon: "", //
        splash: "", //
        google_service: "",
        android: {
          permissions: [],
        },
        ios: {
          infoPlist: {},
        },
      },
      menu_logo_file: null,
      icon_file: null,
      splash_file: null,
      google_service_file: null,
      menu_logo_url: "",
      icon_url: "",
      splash_url: "",
      modal: false,
      editBalance: 0,
      isBusy: true,
    };
  },
  async mounted() {
    const { data } = await this.$http.get(
      "https://api.data-krat.ru/v2/app/all"
    );
    this.apps = data;
    this.isBusy = false;
  },
  methods: {
    addIos() {
      // this.preset.ios.infoPlist[this.iosSelect] = '';
      this.preset.ios.infoPlist = {
        ...this.preset.ios.infoPlist,
        [this.iosSelect]: "",
      };
    },
    dellIos(index) {
      console.log(index);
      this.$delete(this.preset.ios.infoPlist, index);
    },
    async openModal(row) {
      this.app = row;
      this.modal = true;
      const { data } = await this.$http.get(
        "https://api.data-krat.ru/v2/app/admin/preset?app=" + this.app.name
      );
      if (!data) {
        this.preset = {
          app_title: "",
          app_token: "",
          main_color: "",
          second_color: "",
          shop_type: "",
          cart: false,
          popular: false,
          promocode: false,
          discount_matrix: false,
          clm: false,
          shop_delivery: false,
          shop_online_pay: false,
          scan_card: false,
          not_show_discount: false,
          shop_cash_in_delivery: false,
          product_scan: false,
          web_shop_url: "",
          share_title: "",
          pickup: false,
          share_message: "",
          share_url: "",
          init_lat: "",
          init_lon: "",
          menu_logo: "", //
          icon: "", //
          splash: "", //
          google_services: "",
          android: {
            permissions: [],
          },
          ios: {
            infoPlist: {},
          },
        };
      } else {
        if (!data.ios) {
          data.ios = {
            infoPlist: {},
          };
        }
        this.preset = data;
      }

      if (data.menu_logo)
        this.menu_logo_url = "https://api.data-krat.ru/image/" + data.menu_logo;
      else this.menu_logo_url = "";
      if (data.icon)
        this.icon_url = "https://api.data-krat.ru/image/" + data.icon;
      else this.icon_url = "";
      if (data.splash)
        this.splash_url = "https://api.data-krat.ru/image/" + data.splash;
      else this.splash_url = "";
    },
    calculateDays(dt) {
      let data = new Date(dt);
      let today = Date.now();
      let days = Math.floor((data - today) / (1000 * 60 * 60 * 24));
      if (days > 0) {
        return days;
      } else return "Не оплачено";
    },
    setMenuLogo() {
      this.menu_logo_file = this.$refs.menu_logo.files[0];
      this.menu_logo_url = URL.createObjectURL(this.menu_logo_file);
    },
    async sendMenuLogo() {
      let formData = new FormData();
      formData.append("image", this.menu_logo_file);
      try {
        const { data } = await this.$http.post(
          `https://images.data-krat.ru/images/presets?app=${this.app.name}&type=menu_logo`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.$notify({
          group: "foo",
          title: "Успешно",
          type: "success",
          text: "меню лого успешно загруженно",
        });
      } catch (e) {
        this.$notify({
          group: "foo",
          title: "Important message",
          type: "error",
          text: "Hello user! This is a notification!",
        });
      }
    },
    setIcon() {
      this.icon_file = this.$refs.icon.files[0];
      this.icon_url = URL.createObjectURL(this.icon_file);
    },
    async sendIcon() {
      let formData = new FormData();
      formData.append("image", this.icon_file);
      const { data } = await this.$http.post(
        `https://images.data-krat.ru/images/presets?app=${this.app.name}&type=icon`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.$notify({
        group: "foo",
        title: "Успешно",
        type: "success",
        text: "меню иконка успешно загружена",
      });
    },
    setSplash() {
      this.splash_file = this.$refs.splash.files[0];
      this.splash_url = URL.createObjectURL(this.splash_file);
    },
    async sendSplash() {
      let formData = new FormData();
      formData.append("image", this.splash_file);
      const { data } = await this.$http.post(
        `https://images.data-krat.ru/images/presets?app=${this.app.name}&type=splash`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.$notify({
        group: "foo",
        title: "Успешно",
        type: "success",
        text: "splash успешно загружена",
      });
    },

    setGoogleService() {
      this.google_service_file = this.$refs.google_service.files[0];
    },
    async sendGoogleService() {
      let formData = new FormData();
      formData.append("image", this.google_service_file);
      const { data } = await this.$http.post(
        `https://images.data-krat.ru/images/presets?app=${this.app.name}&type=google_services`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.$notify({
        group: "foo",
        title: "Успешно",
        type: "success",
        text: "google service успешно загружена",
      });
    },
    async savePreset() {
      const { data } = await this.$http.post(
        "https://api.data-krat.ru/v2/app/admin/preset?app=" + this.app.name,
        this.preset
      );
      console.log(data);
      this.$notify({
        group: "foo",
        title: "Успешно",
        type: "success",
        text: "пресет успешно загружен",
      });
    },
    async saveApp() {
      const { data } = await this.$http.post(
        "https://api.data-krat.ru/v2/app/update/" + this.app.name,
        this.app
      );
    },
  },
};
</script>


<style scoped>
</style>