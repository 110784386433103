import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as axios from "axios";
import './assets/main.scss'
import 'bootstrap/dist/js/bootstrap.bundle'
import Notifications from 'vue-notification'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually



Vue.config.productionTip = false;

Vue.use(DateRangePicker);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications)

Vue.prototype.$http = axios;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
